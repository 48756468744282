import React from 'react';
import { MDBBtn } from 'mdbreact';
import { siteConfig } from '../siteConfig'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const { SITE_NAME, PAGE_ROOT } = siteConfig

class PromoBannerSurvey2024 extends React.Component {
    render() {
        const locale = this.props.locale
        return (
            <React.Fragment>

                {/* {
                NOT WORTH IT - praktycznie nikt nie ocenia
                    SITE_NAME === "GDJ" && locale !== "en" &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>
                            <a href="https://jobboersencheck.de/fragebogen?jm=451" target='_blank' className='orange-text font-weight-bold'>Bewerte uns auf JobbörsenCheck.de</a> und gewinne einen 250 EUR Amazon Gutschein!
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                } */}
                {
                    SITE_NAME === "RDJ" && locale !== "en" &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>Pune umărul la dezvoltarea pieței de joburi în IT
                            <a href="https://devjob.ro/tech-job-market-survey" target='_blank' className='orange-text font-weight-bold'> răspunzând câtorva întrebări</a>
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                }
                {
                    (["UKJ", "USJ", "GDJ"].includes(SITE_NAME) || (SITE_NAME === "GDJ" && locale === "en")) &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>Make the IT job market better
                            <a href={PAGE_ROOT + "/tech-job-market-survey"} target='_blank' className='orange-text font-weight-bold'> by participating in a 3 min survey</a>
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                }
                {
                    (["SDJ"].includes(SITE_NAME)) &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>Celebrate our 30k followers and
                            <a href={"https://www.linkedin.com/posts/swissdev-jobs_always-wanted-to-get-a-cool-swissdevjobs-activity-7267500266298523649-kJTW"} target='_blank' className='orange-text font-weight-bold'> win a SwissDevJobs goodie bag</a>
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                }
                {/* {
                    (["USJ"].includes(SITE_NAME)) &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>Join our
                            <a href={"https://t.me/devitjobs_us"} target='_blank' className='orange-text font-weight-bold'> Telegram channel </a>
                            to receive daily job offers
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                } */}
                {/* {
                    ["SDJ"].includes(SITE_NAME) &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 py-md-5 text-center" + this.props.className}>
                        <span className='text-very-large '>
                            <a href={"https://www.startup.ch/swissdevjobs"} target='_blank' onClick={() => plausible('top100StartupsRedirect')} className='orange-text font-weight-bold'>Vote for SwissDevJobs <br className="only-mobile" />in the Top 100 Swiss Startups Awards</a>
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-3 mr-md-5 pr-md-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                } */}
            </React.Fragment>
        )
    }
}

export default PromoBannerSurvey2024;